import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';

window.addEventListener('DOMContentLoaded', () => {
   const wpEmbedEls = Array.from(document.querySelectorAll('.wp-embed'));
   wpEmbedEls.forEach(wpEmbedEl => {
      addGAtoAnyLink(wpEmbedEl);
   });
});

const addGAtoAnyLink = wpArticleEl => {
   const links = wpArticleEl.querySelectorAll('a');
   const slug = App.wpSlug;
   links.forEach(link => {
      link.addEventListener('click', () => {
         trackInPiwikAndGA({
            eventCategory: 'Wordpress Link',
            eventAction: `WP Link - WPId-${slug}-Click-${link.href}`,
         });
      });
   });
};
